.item-scroll {
    overflow-y: scroll;
    max-height: 21rem;
    overflow-x: hidden;
}


.item-scroll::-webkit-scrollbar {
    /* Kaydırma çubuğu stil ve genişlik */
    width: 10px;
    border-radius: 5px;
}

.item-scroll::-webkit-scrollbar-thumb {
    /* Kaydırma göstergesi stil ve rengi */
    background-color: #d3d3d3;
    border-radius: 5px;
}

.item-scroll::-webkit-scrollbar-thumb:hover {
    /* Kaydırma göstergesi stil ve rengi (hover durumu) */
    background-color: #727272;
}

input {
    box-shadow: none !important;
}